import React from "react";
import CookieConsent from "react-cookie-consent";
import { Outlet } from "react-router";

const CookieConsentBanner = ({ children }) => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (!refresh_token) {
    window.location.href = "/login";
  } else {
    return (
      <>
        <CookieConsent
          location="bottom"
          hideOnAccept="true"
          buttonStyle={{ color: "#4e503b", borderRadius: "5px" }}
          style={{ zIndex: 10000, padding: "20px 30px", fontSize: "18px" }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Outlet />
        {children}
      </>
    );
  }
};

export default CookieConsentBanner;
