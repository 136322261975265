import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { user_details } from "src/helper/axios-instance/create_test";
import Cookies from "js-cookie";
import { CSpinner } from "@coreui/react";

export const AuthContext = createContext({});
const userReducer = (state, action) => {
  switch (action.type) {
    case "STORE_USER_DATA":
      const { first_name, organization_id, role, permissions,organization_public      } = action.payload;

      return {
        userData: {
          ...state,
          first_name: first_name,
          organization_id: organization_id,
          role: role,
          permissions: permissions,
          organization_public:organization_public
        },
      };
    case "LOGOUT":
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      return null;
    default:
      return state;
  }
};
export const AuthProvider = (props) => {
  const [userData, dispatch] = useReducer(userReducer, null);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (access_token) {
      setLoading(true);
      const getUserDetails = async () => {
        await user_details
          .get(`/profile/view`)
          .then((response) => {
            setLoading(false);
            const { first_name, organization_id, role,organization_public
            } =
              response.data.data.user_details;
  
            dispatch({
              type: "STORE_USER_DATA",
              payload: {
                first_name: first_name,
                organization_id: organization_id,
                role: role,
                permissions: response.data.permission,
                organization_public:organization_public
              },
            });
          })
          .catch((error) => {
            setLoading(false);
          });
      };
      getUserDetails();
    }
  }, []);
  const login = async (value) => {
    dispatch({
      type: "STORE_USER_DATA",
      payload: {
        first_name: value?.data.first_name,
        organization_id: value?.data.organisation_id,
        role: value?.data.role,
        permissions: value?.data.permission,
      },
    });
    // setUser(value);

    navigate(
      !value.data.organisation_id && value.data.role == 2
        ? "/organinsation"
        : "/"
    );
  };

  const logout = async () => {
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const value = useMemo(
    () => ({
      login,
      logout,

      userData,
      dispatch,
    }),
    [userData]
  );
  if (loading == true) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100dvh" }}
      >
        <CSpinner />
      </div>
    );
  }
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
