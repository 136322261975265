const API_SERVER = process.env.REACT_APP_API_SERVER;

const AUTH_API = API_SERVER + "/auth/";
const COMMON_API = API_SERVER + "/tests";
const NEW_TOKEN = API_SERVER + "/create/token";
const MODULE = API_SERVER + "/module";
const CANDIDATE = API_SERVER + "/candidate";
const USERS = API_SERVER + "/users";
const SETTINGS = API_SERVER + "/settings";
const ORGANISATION = API_SERVER + "/organisation";
const DASHBOARD = API_SERVER + "/dashboard";
const NOTIFICATION = API_SERVER + "/notification";

export default {
  API_SERVER,
  AUTH_API,
  COMMON_API,
  NEW_TOKEN,
  MODULE,
  CANDIDATE,
  USERS,
  SETTINGS,
  ORGANISATION,
  DASHBOARD,
  NOTIFICATION,
};
