import axios from "axios";

import serverPath from "../serverPath";

const removeAllCookies = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  window.location = "/login";
};

const createAxiosPublicInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};

const createAxiosPrivateInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    async (req) => {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        removeAllCookies();
      } else {
        req.headers.Authorization = `Bearer ${access_token}`;
        return req;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const req = error.config;
      if (error.response.status === 401 && !req._retry) {
        req._retry = true;
        try {
          const refresh_token = localStorage.getItem("refresh_token");
          if (!refresh_token) {
            throw error;
          }
          const response = await axios.post(`${serverPath.NEW_TOKEN}`, {
            refresh_token: refresh_token,
          });
          const access_token = response.data.data.access_token;
          localStorage.setItem("access_token", access_token);
          req.headers.Authorization = `Bearer ${access_token}`;
          return axios(req);
        } catch (error) {
          removeAllCookies();
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

//private instances
export const create_test = createAxiosPrivateInstance(serverPath.COMMON_API);
export const view_test = createAxiosPrivateInstance(serverPath.COMMON_API);
export const users_list = createAxiosPrivateInstance(serverPath.USERS);
export const module = createAxiosPrivateInstance(serverPath.MODULE);
export const Candidate = createAxiosPrivateInstance(serverPath.CANDIDATE);
export const user_details = createAxiosPrivateInstance(serverPath.SETTINGS);
export const organisation = createAxiosPrivateInstance(serverPath.ORGANISATION);
export const Dashboard_details = createAxiosPrivateInstance(
  serverPath.DASHBOARD
);
export const notification_details = createAxiosPrivateInstance(
  serverPath.NOTIFICATION
);
export const changePassword = createAxiosPrivateInstance(
  serverPath.AUTH_API + "password/change"
);
//public instances
export const public_user = createAxiosPublicInstance(serverPath.USERS);

