import { Outlet } from "react-router-dom";

const AnonymousRoute = () => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (!refresh_token) {
    return <Outlet />;
  } else {
    window.location.href = "/";
  }
};

export default AnonymousRoute;
